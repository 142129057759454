<template>
  <BaseModal :shown="show" @close="answer(false)" close-button>
    <div class="modal-prompt">
      <h3 class="modal__title">{{ modalData?.title }}</h3>
      <p class="modal__desc">{{ modalData?.description }}</p>
      <ul class="modal-prompt__items" v-if="modalData?.items?.length">
        <li v-for="item in modalData?.items ?? []" :key="item">{{ item }}</li>
      </ul>
      <div class="modal-prompt__actions">
        <BaseButton v-if="modalData?.danger || modalData?.decline" :color-primary="!!modalData?.danger" :outlined="!modalData?.danger" big @click="answer(false)">{{ modalData?.decline ?? 'Отмена' }}</BaseButton>
        <BaseButton :color-danger="!!modalData?.danger" big @click="answer(true)">{{ modalData?.accept ?? 'Ок' }}</BaseButton>
      </div>
    </div>
  </BaseModal>
</template>

<script setup>
import BaseModal from '@/components/common/BaseModal'
import { answer, promptConstruct } from '~/composables/prompt'

const emit = defineEmits(['provide'])

const modalData = ref({})
const show = ref(false)
const before = data => {
  modalData.value = data
  show.value = true
}
const after = () => {
  modalData.value = {}
  show.value = false
}

const prompt = promptConstruct(before, after)
emit('provide', prompt)
</script>

<style scoped lang="scss">
.modal-prompt {
  color: var(--color-elements-primary);
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  &__items {
    margin-top: 8px;
  }
  &__actions {
    margin-top: 32px;
    display: flex;
    gap: 24px;
    align-items: center;

    > * {
      flex: 1;
    }

    @media (max-width: 920px) {
      margin-top: 24px;
      flex-direction: column;
      align-items: stretch;
      gap: 16px;
    }
  }
}
</style>
