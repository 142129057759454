export const answer = value => window.dispatchEvent(new CustomEvent('prompt', { detail: { value } }))

const noop = () => {}

export const promptConstruct = (beforeFn = noop, afterFn = noop) => async data => {
  beforeFn(data)
  const value = await new Promise(resolve => {
    const listener = evt => {
      window.removeEventListener('prompt', listener)
      resolve(evt.detail.value)
    }
    window.addEventListener('prompt', listener)
  })
  afterFn(data)
  return value
}
